import React from 'react';
import './VideoContainer.css';

const VideoContainer = ({
  videoUrl,
  circles,
  onVideoClick,
  onCircleClick,
  containerStyles,
}) => {
  const handleVideoClick = (e) => {
    const rect = e.target.getBoundingClientRect(); // Получаем размеры и положение видео
    const x = e.clientX - rect.left; // Вычисляем X относительно видео
    const y = e.clientY - rect.top; // Вычисляем Y относительно видео

    onVideoClick({ x, y });
  };

  return (
    <div className="VideoContainer" style={containerStyles}>
      {videoUrl ? (
        <>
          <video
            src={videoUrl}
            // controls
            autoPlay
            onClick={handleVideoClick} // Обработчик клика на видео
          />
          {circles?.map((circle, index) => (
            <div
              key={index}
              className="circle"
              style={{
                top: `${circle.y - circle.radius}px`,
                left: `${circle.x - circle.radius}px`,
              }}
              onClick={() => onCircleClick(circle, index)}
            >
              {index + 1}
            </div>
          ))}
        </>
      ) : (
        <p>No Video</p>
      )}
    </div>
  );
};

export default VideoContainer;
