import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VideoContainer from '../../components/VideoContainer/VideoContainer';
import Header from '../../components/Header/Header';

const Admin = ({ setCirclesData }) => {
  const [videoFile, setVideoFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [error, setError] = useState(false);
  const [circles, setCircles] = useState([]);
  const navigate = useNavigate();

  console.log(circles);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setVideoFile(file);
      setVideoUrl(URL.createObjectURL(file));
      setCircles([]);
    } else {
      setError(true);
      setVideoFile(null);
    }
  };

  const handleAddCircle = ({ x, y }) => {
    const newCircle = { x, y, radius: 30, videoFile: null };
    setCircles([...circles, newCircle]);
  };

  const handleCirclePlay = (index, e) => {
    const file = e.target.files[0];
    setCircles(
      circles.map((circle, i) =>
        i === index ? { ...circle, videoFile: file } : circle
      )
    );
  };

  const circleDelete = (index) => {
    setCircles(circles.filter((_, i) => i !== index));
  };

  const saveData = () => {
    if (videoFile && circles.length > 0) {
      setCirclesData({ videoFile, circles });
      navigate('/');
    } else {
      alert('Пожалуйста, загрузите видео и добавьте хотя бы один круг.');
    }
  };

  return (
    <div className="Admin">
      <Header text="Upload Video" />
      <div className="base-video">
        <VideoContainer
          videoUrl={videoUrl}
          circles={circles}
          onVideoClick={handleAddCircle} // Передаем обработчик клика
          onCircleClick={(circle, index) =>
            console.log('Circle clicked', index)
          }
        />
        <input type="file" accept="video/*" onChange={handleFileChange} />
      </div>
      <section className="sub-video">
        <ul>
          {circles.map((circle, index) => (
            <li key={index}>
              <input
                type="file"
                accept="video/*"
                onChange={(e) => handleCirclePlay(index, e)}
              />
              <button onClick={() => circleDelete(index)}>X</button>
            </li>
          ))}
        </ul>
      </section>
      <button className="submit" onClick={saveData}>
        Upload
      </button>
    </div>
  );
};

export default Admin;
