import React, { useState, useEffect } from 'react';
import VideoContainer from '../../components/VideoContainer/VideoContainer';
import Header from '../../components/Header/Header';

const Home = ({ circlesData }) => {
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    if (circlesData?.videoFile) {
      const url = URL.createObjectURL(circlesData.videoFile);
      setVideoUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [circlesData]);

  const handleCircleClick = (circle) => {
    if (circle.videoFile) {
      const url = URL.createObjectURL(circle.videoFile);
      setVideoUrl(url);
    }
  };

  return (
    <div className="Home">
      <Header text="Home" />
      <VideoContainer
        videoUrl={videoUrl}
        circles={circlesData?.circles}
        onCircleClick={handleCircleClick}
      />
    </div>
  );
};

export default Home;
